const initialState = {
    selectedUser: null,
    selectedMachine: null,
    machines: [],
    badMachines: [],
    serverError: false,
}

const reducer = (state = initialState, action) => {
    var newState = null;
    if (action.type === "DISPLAY_MACHINE") {
        var machineName = action.data.machine_name;
        newState = JSON.parse(JSON.stringify(state));
        // newState.serverError = checkServerStatus(action.data);
        // console.log(newState.serverError);
        for (var m in newState.machines) {
            if (newState.machines[m].machine_name === machineName) {
                newState.machines[m] = action.data;
                return newState;
            }
        }
        newState.machines.push(action.data);
        return newState;
    } else if (action.type === "BAD_MACHINE") {
        newState = JSON.parse(JSON.stringify(state));
        if (newState.badMachines.indexOf(action.data.machine_name) === -1) {
            newState.badMachines.push(action.data.machine_name);
        }
        return newState;
    } else if (action.type === 'SELECT_MACHINE') {
        newState = JSON.parse(JSON.stringify(state));
        newState.selectedMachine = action.data;
        return newState;
    } else if (action.type === 'SELECT_USER') {
        newState = JSON.parse(JSON.stringify(state));
        newState.selectedUser = action.data;
        return newState;
    } else {
        return state;
    }    

};

export default reducer;