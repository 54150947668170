import React from 'react';
import { connect } from 'react-redux';

const userItem = (props) => (
    <tr onClick={() => props.onClickHandler(props.userName)}>
        <td style={{textAlign: "left"}} colSpan="12">{props.userName}</td>
    </tr>
);

const mapDispatchToProps = dispatch => {
    return {
        onClickHandler: (userName) => dispatch({type: 'SELECT_USER', data: userName})
    }
}

export default connect(null, mapDispatchToProps)(userItem);