import React from 'react';
import './PersonalPage.scss';
import { Container, Row, Col, Media } from 'reactstrap';

// import profileImg2 from './profile.jpeg';
const profileImg = require('./profile.jpeg').default;


const personalPage = (props) => (
    <Container fluid={true}>
        <Row>
            <Col className="imageCol" xs="12" md="6">
                {/* <img className="profileImg" src={profileImg} /> */}
                <Media left top className="profileImg" src={profileImg} />
                {/* <img src={require('./profile.jpeg').default}/> */}
                {/* <img src={profileImg2}/> */}
            </Col>
            <Col className="personalInfoCol">
                <p className="personalInfo">
                    I am a PhD student at the CS department at Tel-Aviv University under the supervision of <a href="http://www.cs.tau.ac.il/~gamir/">Prof. Amir Globerson</a>. Prior to that, I completed an M.Sc in CS in 2018 and a B.Sc in Mathematics and CS in 2015 both from TAU.
                    Between 2018-2022 I was a member of the <a href="https://research.ibm.com/interactive/project-debater/">Debater project</a> at IBM research.
                    {/* Since 2018 I have been a member of the IBM research lab working on the Debater project. */}
                </p>
            </Col>
        </Row>
        <Row className="researchInfo">
            {/* <h2>Publications</h2> */}
            <Col>
                <h2>Teaching</h2>
                <ul>
                    <li>Workshop in Machine Learning 2019-2022</li>
                    <li>Foundations of Deep Learning 2020</li>
                    <li>Introduction to Machine Learning 2017-2018</li>
                </ul>
                <h2>Publications</h2>
                <ul className="publicationList">
                    <li className="publicationItem">
                        <a href="https://arxiv.org/pdf/2202.04302.pdf">On the Implicit Bias of Gradient Descent for Temporal Extrapolation</a><br/>
                        <b>E Cohen-Karlik</b>, A Ben David, N Cohen, A Globerson<br/>
                        International Conference on Artificial Intelligence and Statistics (AISTATS) 2022
                    </li>
                    <li className="publicationItem">
                        <a href="https://www.nature.com/articles/s41586-021-03215-w">An autonomous debating system</a><br/>
                        N Slonim, Y Bilu, C Alzate, R Bar-Haim, B Bogin, F Bonin, L Choshen, <b>E Cohen-Karlik</b>, L Dankin, L Edelstein, L Ein-Dor,<br/>
                        R Friedman-Melamed, A Gavron, A Gera, M Gleize, S Gretz, D Gutfreund, A Halfon, D Hershcovich, R Hoory, Y Hou, S Hummel,<br/>
                        M Jacovi, C Jochim, Y Kantor, Y Katz, D Konopnicki, Z Kons, L Kotlerman, D Krieger, D Lahav, T Lavee, R Levy, N Liberman,<br/>
                        Y Mass, A Menczel, S Mirkin, G Moshkowich, S Ofek-Koifman, M Orbach, E Rabinovich, R Rinott, S Shechtman, D Sheinwald,<br/>
                        E Shnarch, I Shnayderman, A Soffer, A Spector, B Sznajder, A Toledo, O Toledo-Ronen, E Venezian, R Aharonov<br/>
                        Nature 2021
                    </li>
                    <li className="publicationItem"> 
                        <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8186397/pdf/fcell-09-674710.pdf">Quantification of osteoclasts in culture, powered by machine learning</a><br/>
                        <b>E Cohen-Karlik</b>, Z Awida, A Bergman, S Eshed, O Nestor, M Kadashev, S Ben Yosef, H Saed, Y Mansour, A Globerson, D Neumann, Y Gabet<br/>
                        Frontiers in Cell and Developmental Biology 2021
                    </li>
                    <li className="publicationItem"> 
                        <a href="https://arxiv.org/pdf/2010.13055.pdf">Regularizing Towards Permutation Invariance in Recurrent Models</a><br/>
                        <b>E Cohen-Karlik</b>, A Ben David, A Globerson<br/>
                        Advanced in Neural Information Processing Systems (NeurIPS) 2020
                    </li>
                    <li className="publicationItem"> 
                        <a href="https://arxiv.org/pdf/1911.11408.pdf">A Large-scale Dataset for Argument Quality Ranking: Construction and Analysis</a><br/>
                        S Gretz, R Friedman, <b>E Cohen-Karlik</b>, A Toledo, D Lahav, R Aharonov, N Slonim <br/>
                        Association for the Advancement of Artificial Intelligence (AAAI) 2020
                    </li>
                    <li className="publicationItem"> 
                        <a href="https://arxiv.org/pdf/1906.03897.pdf">Learning to combine Grammatical Error Corrections</a><br/>
                        Y Kantor, Y Katz, L Choshen, <b>E Cohen-Karlik</b>, N Liberman, A Toledo, A Menczel, N Slonim <br/>
                        Workshop at Association for Computational Linguistics (ACL) 2019
                    </li>
                    <li className="publicationItem"> 
                        <a href="https://arxiv.org/pdf/1909.01007.pdf"> Automatic Argument Quality Assessment--New Datasets and Methods</a> <br/>
                        A Toledo, S Gretz, <b>E Cohen-Karlik</b>, R Friedman, E Venezian, D Lahav, M Jacovi, R Aharonov, N Slonim<br/>
                        Empirical Mehtods in Natural Language Processing (EMNLP) 2019
                    </li>
                    <li className="publicationItem"> 
                    <a href="https://www.cs.yale.edu/homes/aspnes/papers/podc2017-vac-ba-proceedings.pdf">Object Oriented Consensus</a><br/>
                        Y Afek, J Aspnes, <b>E Cohen</b>, D Vainstein <br/>
                        Brief announcement at Principles of Distributed Computing (PODC) 2017
                    </li>
                </ul>
            </Col>
        </Row>
    </Container>)

export default personalPage;