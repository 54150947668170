import React from 'react';

import { Card, CardTitle, CardText, Container, Row, Col } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';

const processCard = (props) => (
    <div>    
        <Card body className="text-left card" body outline>
            <CardTitle>User: <b>{props.username}</b></CardTitle>
            <CardTitle>Machine: <b>{props.machine}</b></CardTitle>
            <CardText>PID: {props.pid} | GPUs: [{props.gpus.toString()}]</CardText>
            <CardText>Running Since: {props.runtime}</CardText>
            <Container>
                <Row>
                    <Col>
                        <CircularProgressbar value={props.gpuUtil} text={`${props.gpuUtil}%`} />
                        GPU Util.
                    </Col>
                    <Col>
                        <CircularProgressbar value={props.memUtil} text={`${props.memUtil}%`} />
                        Mem Util.
                    </Col>
                </Row>
            </Container>
        </Card>
    </div>
);

export default processCard;