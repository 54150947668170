import React, { Component } from 'react';

import './App.scss';
import Layout from './components/Layout/Layout';

class App extends Component {
    componentDidMount() {
        document.title = "Edo Cohen-Karlik";
    }

    render () {
        return (
            <div className="App">
                <Layout/>
            </div>
        )
    }
}

export default App;