import React from 'react';
import { Container, CardColumns } from 'reactstrap';
  
import ProcessCard from './ProcessCard/ProcessCard';
import 'react-circular-progressbar/dist/styles.css';
import './SelectedProcesses.scss';

const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

function parseProcesses(processList) {
    var pd = {}
    for (var p in processList) {
        var user = processList[p][0];
        var pid = processList[p][1];
        var gpu = processList[p][2];
        var machine = processList[p][3];
        var runtime = processList[p][4];
        var memUtil = processList[p][5];
        var gpuUtil = processList[p][6];
        if (typeof(pd[pid]) === "undefined") {
            pd[pid] = {
                user: user,
                machine: machine,
                gpu: [gpu],
                runtime: runtime,
                memUtil: [Math.floor(memUtil)],
                gpuUtil: [Math.floor(100*gpuUtil)]
            }
        } else {
            pd[pid].gpu.push(gpu);
            pd[pid].memUtil.push(Math.floor(memUtil));
            pd[pid].gpuUtil.push(Math.floor(100*gpuUtil));
        }
    }
    var cardList = [];
    for (var i in pd) {
        cardList.push(
            <ProcessCard key={i}
                username={pd[i].user}
                machine={pd[i].machine}
                pid={i}
                gpus={pd[i].gpu}
                runtime={pd[i].runtime} 
                gpuUtil={Math.floor(average(pd[i].gpuUtil))}
                memUtil={Math.floor(average(pd[i].memUtil))}
            />
        );
    }
    return cardList;
}


const selectedObject = (props) => ((() => {
    if (props.title != null) {
        return  <Container className='cardContainer'>
                    <div className='headline'>
                        <h4 className='title'>{props.title}</h4>
                        {/* <h5 className='title'>Last updated: {props.updateTime}</h5> */}
                    </div>
                    <CardColumns className='cardGroup'>
                        {parseProcesses(props.children)}
                    </CardColumns>
                </Container>
    } 
    return <h1>Select an object</h1>;})()
)

export default selectedObject;