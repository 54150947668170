import React from 'react';
import { connect } from 'react-redux';
import './MachineItem.scss';

function parse(freeGPUs, usedGPUs) {
    var arr = [...Array(8)].map((e, i) => <td key={i}></td>)
    var prefix = 'GPU';
    if (window.innerWidth <= 600) {
        prefix = 'G';
    }

    if (!(freeGPUs === undefined || freeGPUs.length === 0)) {
        for (let i of freeGPUs) {
            arr[i] = <td key={i}><div className="freeBox"><b className="freeText">{prefix + i}</b></div></td>;
        }
    }

    if (!(usedGPUs === undefined || usedGPUs.length === 0)) {
        for (let i of usedGPUs) {
            arr[i] = <td key={i}><div className="usedBox"><b className="usedText">{prefix + i}</b></div></td>;
        }
    }

    return arr;
}

const machineItem = (props) => {
    return (
        <tr  onClick={() => props.onClickHandler(props.machineName)}>
        <td style={{textAlign: "left"}} colSpan="3">{props.machineName}</td>
        {parse(props.freeGPUs, props.usedGPUs)}
        <td></td>
    </tr>);
};

const mapDispatchToProps = dispatch => {
    return {
        onClickHandler: (machineName) => dispatch({type: 'SELECT_MACHINE', data: machineName})
    }
}

export default connect(null, mapDispatchToProps)(machineItem);
