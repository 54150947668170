import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import axiosFirebase from '../../axios-read';
import Loader from 'react-loader-spinner'
import './MachinePage.scss';

import Aux from '../../hoc/Aux';
import ObjectList from '../../components/ObjectList/ObjectList';
import SelectedProcesses from '../../components/SelectedProcesses/SelectedProcesses';
import MachineItem from '../../components/MachineItem/MachineItem';
import UserItem from '../../components/UserItem/UserItem';


class MachinePage extends Component {
	state = {
		dataLoaded: false,
	}

	componentDidMount() {
		this.getItemsFromFirebase() // run once and then refresh every 3 seconds.
		this.timer = setInterval(()=> this.getItemsFromFirebase(), 3000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		this.timer = null;
	}

	getMachineFromFirebase(machine_name) {
		axiosFirebase.get(machine_name + '.json')
			.then(res => {
				var serverDelay = 0;
				var statusDelay = 0;
				var writeTime = new Date(res.data['write_time'].replace(/-/g, '/'));
				var updateTime = new Date(res.data['update_time'].replace(/-/g, '/'));
				var now = new Date();
				now.setHours(now.getHours() - 3); // convert to GMT
				serverDelay = (writeTime - updateTime)/1000;
				statusDelay = (now - writeTime)/1000;
				var newRes = {
					...res.data,
					"machine_name": machine_name
				}
				if (serverDelay < 120 && statusDelay < 300) {
					this.props.getMachineProp(newRes);
					if (!this.state.dataLoaded) {
						this.setState({ dataLoaded: true });
					}
				} else {
					this.props.getBadMachineProp(newRes);
				}
			})
	}

	getItemsFromFirebase() {
		axiosFirebase.get('machines.json').then(res => {
			// console.log('machines from firebase');
			for (let c of res.data) {
				// console.log(c);
				this.getMachineFromFirebase(c);
			}
		})
	}
	parseBadMachines(machines) {
		return  machines.map((row) => (
			<tr style={{textAlign: "left"}}>
				{row}
			</tr>)
		);
	}

	parseAllMachines(machines) {
		return machines.map((row) => (
			<MachineItem 
				key={row['machine_name']}
				machineName={row['machine_name']}
				usedGPUs={row['used_gpus']}
				freeGPUs={row['available_gpus']}
				updateTime={row['update_time']}
				writeTime={row['write_time']}/>)
		);
	}

	parseAllUsers(machines) {
		var returnList = [];
		var allUsersWithDuplicates = [];
		for (var i in machines) {
			var usage_list = machines[i]['usage_list'];
			for (var process in usage_list) {
				var name = usage_list[process][0];
				allUsersWithDuplicates.push(name)
			}
		}
		returnList = [...new Set(allUsersWithDuplicates)]; // remove duplicates.
		return returnList.map((u) => (<UserItem key={u} userName={u}/>));
	}

	getMachineProcs(machineName, machines) {
		for (var row in machines) {
			if (machines[row]['machine_name'] === machineName) {
				return machines[row]['usage_list'];
			}
		}
		return null;
	}

	getMachineUpdateTime(machineName, machines) {
		for (var row in machines) {
			if (machines[row]['machine_name'] === machineName) {
				var updateTime = new Date(machines[row]['update_time'].replace(/-/g, '/'));
				updateTime.setHours(updateTime.getHours() + 2);
				var time = updateTime.toLocaleTimeString('en-US');
				var date = updateTime.toLocaleDateString('en-US');
				return date.concat(' ', time);
			}
		}
		return null;
	}

	getUserProcs(userName, machines) {
		if (userName == null) {
			return null;
		}
		var procs = [];
		for (var row in machines) {
			for (var proc in machines[row]['usage_list']) {
				if (machines[row]['usage_list'][proc][0] === userName) {
					procs.push(machines[row]['usage_list'][proc])
				}
			}
		}
		return procs;
	}

	displayMachines(props) {
		return (<Container fluid={true}>
					<Row>
						<Col>
							<ObjectList title="Machines">
								{this.parseAllMachines(props.machines)}
							</ObjectList>
							<ObjectList title="Could not obtain status of the following machines:">
								{this.parseBadMachines(props.badMachines)}
							</ObjectList>
						</Col>
						<Col>
							<SelectedProcesses title={props.selectedMachine} updateTime={this.getMachineUpdateTime(props.selectedMachine, props.machines)}>
								{this.getMachineProcs(props.selectedMachine, props.machines)}
							</SelectedProcesses>
						</Col>
					</Row>
				</Container>)
	}

	displayUsers(props) {
		return (<Container fluid={true}>
					<Row>
						<Col>
							<ObjectList title="Users">
								{this.parseAllUsers(props.machines)}
							</ObjectList>
							<ObjectList title="Could not obtain status of the following machines:">
								{this.parseBadMachines(props.badMachines)}
							</ObjectList>
						</Col>
						<Col>
							<SelectedProcesses title={props.selectedUser}>
								{this.getUserProcs(props.selectedUser, props.machines)}
							</SelectedProcesses>
						</Col>
					</Row>
				</Container>)
	}

	render () {
		return (
			<Aux>
				{ (() => {
					if (this.props.serverError) {
						return (
							<Aux>
								<h1 className="loadingDataText">Server Error...</h1>
							</Aux>
						)
					} else if (this.state.dataLoaded) {
						if (this.props.location.pathname === "/dashboard/users") {
							return this.displayUsers(this.props)
						} else if (this.props.location.pathname === "/dashboard/machines") {
							return this.displayMachines(this.props)
						}
					} else {
						return (
							<Aux>
								<Loader
									className="loader"
									type="Grid"
									color="#00BFFF"
									height={100}
									width={100}
									timeout={2000} //3 secs
							
								/>
								<h1 className="loadingDataText">Loading from servers...</h1>
							</Aux>
						)
					}
				})()}
			</Aux>
		);
	}
}

const mapStateToProps = state => {
    return {
		selectedUser: state.selectedUser,
		selectedMachine: state.selectedMachine,
		machines: state.machines,
		badMachines: state.badMachines,
		serverError: state.serverError,
    };
}

const mapDispatchToProps = dispatch => {
    return {
		getMachineProp: (data) => dispatch({type: 'DISPLAY_MACHINE', data: data}),
		getBadMachineProp: (data) => dispatch({type: 'BAD_MACHINE', data: data}),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MachinePage);