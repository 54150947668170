import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import Aux from '../../hoc/Aux';
import MachinePage from '../../containers/MachinePage/MachinePage';
import NavigationBar from '../NavigationBar/NavigationBar';
import PersonalPage from '../PersonalPage/PersonalPage';

const layout = (props) => (
	<Aux>
		<NavigationBar/>
		<Switch>
		  <Route path="/dashboard" component={MachinePage} />
          <Route path="/home" component={PersonalPage} />
          <Redirect to="/home" />
        </Switch>
	</Aux>
);

export default layout;


