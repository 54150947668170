import React from 'react';
import { Link } from 'react-router-dom';

const navigationBar = () => (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <ul className="navbar-nav mr-auto">
            <li><Link to={'/home'} className="nav-link"> <b>Edo Cohen-Karlik</b> </Link></li>
            <li><Link to={'/dashboard/machines'} className="nav-link">TAU Machines</Link></li>
            <li><Link to={'/dashboard/users'} className="nav-link">TAU Users</Link></li>
        </ul>
    </nav>
)

export default navigationBar;