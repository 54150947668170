import React from 'react';

import { Table } from 'reactstrap';
import './ObjectList.scss';

const objectList = (props) => {
    
    return (
        <Table borderless={true} className="mainTable">
            <thead>
                <tr>
                    <th colSpan="12" className="tableTitle">{props.title}</th>
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </Table>
    );
};

export default objectList;